<template>
	<banner-data
		:form-import="form"
		:valid-user-types="validUserTypes"
		:valid-countries="validCountries"
		:valid-device-types="validDeviceTypes"
		:valid-company-types="validCompanyTypes"
		:priorities="priorities"
		:banner-import="bannerInformation"
		:loading="loading"
		:errors-import="errors"
		@requestData="requestUpdateBanner" />
</template>
<script>
import {
	USER_TYPES as validUserTypes,
	DEVICE_TYPES as validDeviceTypes,
	PRIORITIES as priorities,
	COMPANY_TYPES as validCompanyTypes,
} from '@/settings/Banners';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import { Banners as messages } from '@/translations';
import Banner from '@/util/Banner';
import BannerData from './components/BannerData';

export default {
	name: 'UpdateBanner',
	messages,
	components: {
		BannerData,
	},
	data() {
		return {
			banner: new Banner(),
			validUserTypes,
			validCountries,
			priorities,
			validDeviceTypes,
			validCompanyTypes,
			alert: new this.$Alert(),
			loading: false,
			form: {
				title: '',
				image: '',
				mobile_image: '',
				url: '',
				user_types: '',
				countries: '',
				priority: '',
				device_type: '',
				company_types: '',
				start_date: '',
				end_date: '',
				show_in_dashboard: false,
			},
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.banner.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.banner.data.errors.errors = newErrors;
			},
		},
		response() {
			try {
				const { data } = this.banner.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		bannerInformation() {
			return this.data;
		},
		routeId() {
			return this.$route.params.bannerId;
		},
	},
	watch: {
		routeId: () => {
			this.banner.getBanner(this.routeId);
		},
	},
	created() {
		this.banner.getBanner(this.routeId);
	},
	methods: {
		requestUpdateBanner(payload) {
			this.loading = true;
			this.banner.updateBanner(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('banner_updated'));
				this.$router.push({ name: 'Banners' });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
	},
};
</script>
